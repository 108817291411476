<template>
  <!-- 导入线下打款订单弹框 -->
  <el-dialog title="导入绑定机型" :visible.sync="visible" :close-on-click-modal="false" width="540px">
    <div class="cance_tip">
      <div>说明：请按照数据模板上传绑定机型数据</div>
    </div>
    <el-button type="success" icon="el-icon-download" size="small" @click="download">下载数据模板</el-button>
    <el-button :loading="downloadLoading" type="primary" icon="el-icon-download" size="small" @click="downloadLocal">下载本地待绑定机型</el-button>
    <!-- 上传 -->
    <el-form ref="importForm" :model="importForm" :rules="importRules" label-width="80px" style="margin-top: 20px;">
      <el-form-item label="表格上传" prop="fileName" class="fileName">
        <el-input v-model="importForm.fileName" @clear="fileClear" readonly size="medium" clearable>
          <i slot="suffix" class="el-input__icon el-icon-delete" @click="fileClear"></i>
        </el-input>
        <div style="float: right; position: relative">
          <el-button size="medium" type="warning" icon="el-icon-upload2">上传表格</el-button>
          <input accept=".xls, .xlsx" ref="fileUpload" @change="fileChange" type="file" style="
                opacity: 0;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                cursor: pointer;
              " />
        </div>
        <div style="color: #ff687b">
          仅支持.xls及.xlsx格式,单次上传大小不超过5M
        </div>
      </el-form-item>
    </el-form>

    <span slot="footer">
      <el-button @click.native="visible = false">取消</el-button>
      <el-button type="primary" @click.native="confirm">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import _api from "@/utils/request";
import { guideExcelAction } from "@/utils/common";
export default {
  name: 'ImportDia',
  data() {
    return {
      visible: false,
      loading: false,
      downloadLoading: false,

      importForm: {
        fileName: '',
        file: null
      },
      importRules: {},
    };
  },
  methods: {
    open() {
      this.fileClear()
      this.visible = true
    },
    // ************
    // 模板下载
    download() {
      const link = document.createElement("a");
      link.href = "/static/导入绑定机型模板.xlsx";
      link.setAttribute("download", "导入绑定机型模板.xlsx");
      document.body.appendChild(link);
      link.click();
    },
    downloadLocal() {
      this.downloadLoading = true
      _api.excelUnBindFh().then(res => {
        guideExcelAction(res, '本地待绑定机型', () => {
          this.downloadLoading = false
        })
      }).catch(() => {
        this.downloadLoading = false
      })
    },
    // 选择文件
    fileChange(event) {
      if (event.target.files) {
        const file = event.target.files[0]
        this.importForm.file = file;
        this.importForm.fileName = file.name;
      }
    },
    confirm() {
      const file = this.importForm.file
      if (!file) {
        return this.$message.error("请上传表格");
      }
      const fd = new FormData();
      fd.append("file", file)
      this.loading = true
      _api.bindMachineFhBatch(fd).then(res => {
        this.$message({
          type: "success",
          message: '导入成功！',
        });
        this.visible = false
        this.$emit('confirm')
      }).finally(() => {
        this.fileClear()
        this.loading = false
      })
    },
    // 确定导出
    // uploadFile() {
    //   if (!this.paramsExcel.file || this.paramsExcel.file.length == 0) {
    //     return this.$message.error("请上传表格");
    //   }
    //   const fd = new FormData();
    //   console.log(this.paramsExcel);
    //   fd.append("file", this.paramsExcel.file[0]); //文件
    //   // fd.append("supplierId", this.supplierId); //出库商家id
    //   // fd.append("warehouseId", this.warehouseId); //仓库id
    //   console.log(fd);
    //   this.repeatData = []
    //   // 发起请求
    //   _api
    //     .bindMachineFhBatch(fd)
    //     .then((res) => {
    //       console.log("_RequestUploads_", res);
    //       this.importList = res.data
    //       this.isImported=true
    //       console.log(this.importList, '4444444444444444444');
    //       if (res.code === 1) {
    //         this.importForm.fileName = "";
    //         this.$refs.fileUpload.value = "";
    //         let erraction = JSON.parse(JSON.stringify(res))
    //         let erractioncopy = JSON.parse(JSON.stringify(res))
    //         this.seachDataList.unshift(...res.data)
    //         this.$message({
    //           type: "success",
    //           message: '导入成功！',
    //         });
    //       } else {
    //         this.$message({
    //           type: "warning",
    //           message: res.msg,
    //         });
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //       this.importForm.fileName = "";
    //       this.$refs.fileUpload.value = "";
    //     });
    // },
    fileClear() {
      console.log(123)
      if (this.$refs["fileUpload"]) this.$refs["fileUpload"].value = "";
      this.importForm = {
        fileName: '',
        file: null
      }
    },
    // importSuccess() {
    //   _api.importSave({ list: this.importList }).then(res => {
    //     if (res.code === 1) {
    //       this.importVisible = false
    //       this.importList = []
    //       this.isImported=false
    //         this.$message({
    //           type: "success",
    //           message: res.msg || '操作成功',
    //         });
    //     }
    //   })
    // },
    // exportinDialogClosed() {
    //   this.importVisible = false
    //   this.importList = []
    //   this.isImported=false
    // },
  }
}
</script>

<style lang="scss" scoped>
.cance_tip {
  color: #ff8080;
  font-size: 14px;
  margin-bottom: 20px;
}

.fileName {
  /deep/.el-input {
    width: 260px;
  }

  /deep/.el-button {
    // border-radius: 10px;
    margin-right: 0px;
  }
}
</style>