<template>
  <div class="GlobalTable">
    <div class="top-box">
      <!-- <div>
        <TabSelect :tabData="tabData" />
      </div> -->
      <div class="faultBtn">
        <div :class="isBind == '01' ? 'active' : 'none'" @click="isBind = '01'">
          待绑定
          <template v-if="isBind == '01'">
            ({{ page.total }})
          </template>
        </div>
        <div :class="isBind == '02' ? 'active' : 'none'" @click="isBind = '02'">
          已绑定
          <template v-if="isBind == '02'">
            ({{ page.total }})
          </template>
        </div>
      </div>
      <div>
        <el-button v-if="isBind == '01'" size="small" type="success" icon="el-icon-upload" @click="$refs['importDia'].open()">批量导入机型</el-button>
        <el-button :loading="downloadLoading" size="small" type="primary" @click="exportExcel">导出Excel</el-button>
      </div>
       <!-- @click="addModel('import')" -->
    </div>
    <GlobalTable
      ref="GlobalTable"
      v-loading="loading"
      :columns="tableColumns"
      :data="this.seachDataList"
      :currentPage="page.pageNum"
      :total="this.page.total"
      @handleCurrentChange="handleCurrentChange"
    >
      <el-table-column v-if="isBind == '02'" label="绑定本地机型" slot="machineName" align="center">
        <template slot-scope="{ row }">
          {{ row.machineName || '--' }}
        </template>
      </el-table-column>
      <el-table-column v-if="isBind == '02'" label="绑定人" slot="bindUsername" align="center">
        <template slot-scope="{ row }">
          {{ row.bindUsername ? row.bindUsername +'-'+ row.bindAccount : '--'}}
        </template>
      </el-table-column>
      <el-table-column v-if="isBind == '02'" label="绑定时间" prop="bindTime" slot="bindTime" align="center">
        <template slot-scope="{ row }">
          {{ row.bindTime }}
        </template>
      </el-table-column>
      <el-table-column v-if="isBind == '01'" width="250" label="操作" slot="operation" align="center">
        <template slot-scope="{ row }">
          <span class="link primary" style="margin-right:10px" @click="$refs['addModelBindDia'].open(row.channel, row)">关联本地已有机型</span>
          <!-- <span class="link danger" @click="addLocal(row)">添加到本地机型库</span> -->
          <span class="link danger" @click="$refs['addToLocalDia'].open(row)">添加到本地机型库</span>
        </template>
      </el-table-column>
    </GlobalTable>

    <AddModelBindDia ref="addModelBindDia" @confirm="handleCurrentChange(page.pageNum)" />
    <AddToLocalDia ref="addToLocalDia" @confirm="handleCurrentChange(page.pageNum)" />
    <ImportDia ref="importDia" @confirm="handleCurrentChange()" />
  </div>
</template>

<script>
import MinXinRequest from "@/mixins/request";
import _api from "@/utils/request";
import { guideExcelAction } from "@/utils/common";
import { confirm } from '@/components/common/messagebox'
import AddModelBindDia from './dialog/AddModelBindDia.vue';
import AddToLocalDia from "./dialog/AddToLocalDia.vue";
// import TabSelect from '../../../components/common/TabSelect.vue';
import ImportDia from './dialog/ImportDia.vue';

export default {
  components: { AddModelBindDia, ImportDia, AddToLocalDia }, //, TabSelect
  name: "Table",
  mixins: [MinXinRequest],
  props:{
    classList:Array,
    oldBrand:Array,
  },
  data() {
    return {
      downloadLoading: false,
      seachDataList: [],
      pageNum:1,

      tabData: ['待绑定', '已绑定'
        // { text: '待绑定' },
        // { text: '已绑定' },
      ],
      isBind: '01',
      //弹窗
      dialogVisible: false, // 弹框的出现与否
      dialogTitle: "", // 标题
      tableColumns: [
        // { slotName: "channel" },
        { label: "机型来源", prop: "channelName" },
        { label: "外部所属分类", prop: "outTypeName" },
        { label: "外部品牌", prop: "outBrandName" },
        { label: "外部机型ID", prop: "outMachineId" },
        { label: "机型名称", prop: "outMachineName" },
        { label: "添加时间", prop: "createTime" },
        { slotName: "machineName" },
        { slotName: "bindUsername" },
        { slotName: "bindTime" },
        { slotName: "operation" }
      ],
    };
  },
  created() {
    let SeachParams = {
      channel: 'fh',
      pageNum: 1,
      pageSize: 10,
    }
    this.$store.commit('tagsView/SEACH_PARAMS',SeachParams)
    // this.handleCurrentChange();
  },
  computed: {
    seachTotal() {
      return this.$store.state.tagsView.seachTotal;
    },
    SeachParams() {
      return this.$store.state.tagsView.SeachParams;
    }
  },
  watch: {
    SeachParams() {
      this.pageNum = 1
      this.handleCurrentChange();
    },
    isBind() {
      this.pageNum = 1
      this.handleCurrentChange()
    },
  },
  methods: {
    addLocal(row){
      confirm({
        title: '添加提示',
        width: '550px',
        desc: '说明：添加后，本地机型库将新增一条机型，需核实好是否有重复机型',
        message: `是否确认添加<span style="color: red">${row.outMachineName}</span>机型到本地机型库？`,
        confirm: () => {
          _api.doSaveInPlatform({
            channel: row.channel,
            outMachineId: row.outMachineId
          }).then(res => {
            this.$message({
              type: 'success',
              message: '添加成功!'
            });
            this.handleCurrentChange(this.page.pageNum)
          })
        }
      })
    },
    // 表格切换页面
    handleCurrentChange(val) {
      if (val) {
        this.pageNum = val
        this.page.pageNum = val;
      } else {
        this.page.pageNum = 1;
      }
      const baseRequest = {
        isBind: this.isBind,
        machineName: this.SeachParams.machineName,
        channel:this.SeachParams.channel,
        outBrandId: this.SeachParams.outBrandId,
        pageNum: this.page.pageNum,
        pageSize: this.SeachParams.pageSize
      };
      this.$emit("handleCurrentChange", val);
      this.$store.commit("tagsView/POST_TOTAL", 0)
      _api.machineModelOutList(baseRequest).then(res => {
        if (res.code === 1) {
          this.seachDataList = res.data.ipage.records;
          this.page.pageNum = res.data.ipage.current;
          this.page.total = res.data.ipage.total;
          this.$store.commit("tagsView/POST_TOTAL", res.data.ipage.total);
          this.$store.commit("tagsView/POST_RESIDENT", this.seachDataList);
        }
      }).finally(() => {
        if (typeof this.SeachParams.disuseLoding === 'function') {
          this.SeachParams.disuseLoding();
        }
      });
    },
    exportExcel() {
      const { isBind } = this
      this.downloadLoading = true
      _api.machineModelOutExcel({
        isBind,
        machineName: this.SeachParams.machineName,
        channel:this.SeachParams.channel,
        outBrandId: this.SeachParams.outBrandId,
      }).then(res => {
        guideExcelAction(res, `外部机型数据-${isBind == '01' ? '待绑定' : '已绑定'}`, () => {
          this.downloadLoading = false
        })
      }).catch(() => {
        this.downloadLoading = false
      })
    }
  }
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.faultBtn {
  width: 280px;
  height: 34px;
  background: #f9fbfd;
  border: 1px solid #c1d1ff;
  box-shadow: 0px 4px 8px 0px rgba(196, 197, 198, 0.2);
  border-radius: 21px;
  display: flex;
  cursor: pointer;

  .active {
    text-align: center;
    color: white;
    width: 50%;
    height: 14px;
    font-size: 14px;
    font-family: FZLanTingHei-M-GBK;
    font-weight: 400;
    line-height: 32px;
    height: 32px;
    background: #0981ff;
    border-radius: 20px;
  }

  .none {
    text-align: center;
    color: #333;
    width: 50%;
    height: 14px;
    font-size: 14px;
    font-family: FZLanTingHei-M-GBK;
    font-weight: 400;
    line-height: 32px;
    height: 32px;
    border-radius: 20px;
  }
}

.top-box{
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.link{
  text-decoration: underline;
  cursor: pointer;
  &.primary{
    color: #0981ff;
  }
  &.danger{
    color: #ff687b;
  }
}
</style>
