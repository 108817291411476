<template>
  <div class="oldBrand-list">
    <GlobalInfoBar
      title="机型管理"
      content="管理门店店员在报价时，在提交订单时提供机型的快捷查找"
    />
    <GlobalChunk icon="search" title="筛选查询" >
      <div slot="filter">
        <IFrom />
      </div>
      <ITable />
    </GlobalChunk>
  </div>
</template>

<script>
import _api from "@/utils/request";
import IFrom from './From'
import ITable from './Table'
export default {
  name: 'out-oldBrand-list',
  components: { IFrom, ITable },
  data(){
    return{
      
    }
  },
  
}
</script>
